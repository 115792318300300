<template>
    <div>
      <div class="iq-card p-1">
        <div class="row">
          <b-col cols="12">
            <select class="form-control" placeholder="select project" v-model="project"  @change="getAllData">
              <option selected disabled value="">select Project...</option>
              <option v-for="(i, key) in getUserProjects" :key="key" :value="i.id">
                <span>{{i.en_title}}</span>
              </option>
            </select>
          </b-col>
          <div class="col-md-12 my-1">
            <date-range-picker
            class="w-100"
            ref="picker"
            opens=false
            :timePicker=false
            :timePicker24Hour=false
            :showWeekNumbers=true
            :showDropdowns=true
            format='mm/dd/yyyy'
            direction="center"
            :autoApply=true
            v-model="dateRange"
            :linkedCalendars=true
            customRangeLabel="Custom Range"
            @update="getAllData"
          >
          </date-range-picker>
        </div>
        </div>
      </div>
        <b-row>
            <b-col lg="12">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">Members <b-badge variant="primary"> {{ pagination.total }} </b-badge></h4>
                </template>
                <template v-slot:body>
                  <b-table sort-icon-left primary-key="name" :busy="loadTable" striped responsive :fields="columns" :items="allData" class="mb-0 table-borderless "  id="table-transition-example">
                    <template v-slot:table-busy>
                      <div class="text-center">
                        <b-spinner small type="grow"></b-spinner>
                        Loading...
                      </div>
                    </template>
                    <template v-slot:cell(name)="data">
                      <router-link class="linkedReport" :to="{name: 'profile', params:{ id : data.item.member_package_without_scope[0].member_id }}"><b-badge variant="primary">{{ data.item.name }}</b-badge></router-link>
                    </template>
                    <template v-slot:cell(member_package_without_scope)="data">
                      <ul class="m-0 p-0">
                        <li v-for="(i, key) in data.item.member_package_without_scope" :key="key">
                          <span class="font-weight-bold">{{i.package_title}}</span>
                          <span class="text-danger"> {{i.expire}}</span>
                          <span class="text-info"> {{i.last_check_in}}</span>
                          <span> - Project: {{ i.project.en_title }}</span>
                        </li>
                      </ul>
                    </template>
                  </b-table>
                  <b-pagination v-model="pagination.currentPage"
                                class="mt-3"
                                :total-rows="pagination.total"
                                align="right"
                                :per-page="pagination.per_page"
                                aria-controls="my-table"
                                @input="getAllData"
                  ></b-pagination>
                </template>
              </iq-card>
            </b-col>
          </b-row>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { mapGetters } from 'vuex'

import reportServices from '../../services/reports'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DateRangePicker
  },
  data () {
    return {
      project: null,
      dateRange: {
        startDate: null,
        endDate: null
      },
      allData: [],
      loadTable: false,
      soon: null,
      pagination: {
        currentPage: 1,
        total: 0,
        per_page: 10
      },
      columns: [
        { label: 'Member Name', key: 'name' },
        { label: 'Packages and Expire Date', key: 'member_package_without_scope' }
        // { label: 'project', key: 'project.en_title', class: 'text-left' }
      ]
    }
  },

  methods: {
    getAllData () {
      this.loadTable = true
      reportServices.getExpireSoon({
        date_from: this.dateRange.startDate ? new Date(this.dateRange.startDate).toJSON().slice(0, 10) : null,
        date_to: this.dateRange.endDate ? new Date(new Date(this.dateRange.endDate).toJSON().slice(0, 10)).toJSON().slice(0, 10) : null,
        project_id: this.project,
        page: this.pagination.currentPage
      }).then(res => {
        this.loadTable = false
        this.allData = res.data.data.data
        this.pagination.total = res.data.data.total
        this.pagination.per_page = res.data.data.per_page
        this.pagination.currentPage = res.data.data.current_page
      }).finally(() => {
        this.loadTable = false
      })
    }
  },
  computed: {
    ...mapGetters(['getUserProjects'])
  },
  created () {
    this.$store.dispatch('getInfoToCreateLead').then((res) => {
      this.projects = res.data.data.projects
    })
    this.getAllData()
  }
}
</script>
